<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2" v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">

        <div>

            <div class="row d-none">
                <div class="col-md-8">
                    <div class="btn-taps text-center mt-2 mb-1">

                        <button v-for="i_lang in info_page.langs" class="btn btn-wd btn-default ml-1 mr-1" :class="info_page.tab_lang===i_lang.locale?'btn-info':'btn-outline'" @click="info_page.tab_lang=i_lang.locale">{{i_lang.name}}</button>

                    </div>
                </div>
            </div><!--   Hacer el cambio de idioma -->




            <div class="w-border">
                <div class="row">
                    <div class="col-md-8">
                        <fg-input type="text"
                                  :name="$t('form.name')"
                                  :label="$t('form.name')"
                                  :placeholder="$t('form.name')"
                                  v-validate="modelValidations.name"
                                  :error="getError($t('form.name'))"
                                  v-show="info_page.tab_lang==='es'"
                                  v-model="property.es.name">
                        </fg-input>
                        <!--v-validate="modelValidations.name"-->
                        <fg-input type="text"
                                  :name="$t('property.form.name_lang')"
                                  :label="$t('property.form.name_lang')"
                                  :placeholder="$t('property.form.name_lang')"

                                  :error="getError($t('property.form.name_lang'))"
                                  v-show="info_page.tab_lang==='en'"
                                  v-model="property.en.name">
                        </fg-input>
                        <fg-input
                                :label="$t('form.description')"  v-validate="modelValidations.description"  :name="$t('form.description')"  v-model="property.es.description"
                                :error="getError($t('form.description'))" v-show="info_page.tab_lang==='es'">

                            <textarea class="form-control" :placeholder="$t('form.description')"
                                      v-model="property.es.description" rows="7"></textarea>
                        </fg-input>

                        <!--v-validate="modelValidations.description"-->
                        <fg-input
                                :label="$t('property.form.description_lang')"   :name="$t('property.form.description_lang')"  v-model="property.en.description"
                                :error="getError($t('property.form.description_lang'))"  v-show="info_page.tab_lang==='en'">
                            <textarea class="form-control" :placeholder="$t('property.form.description_lang')"
                                      v-model="property.en.description" rows="7"></textarea>
                        </fg-input>
                        
                        
                    </div>
                    <div class="col-md-4 pad-custom custom-error">

                        <div class="box-logo" :class="(property.logo!=='')?'with-img':''">
                            <img :src="property.logo" alt="" v-if="property.logo!==''">
                            <i class="fa fa-camera" v-else></i>

                        </div>
                        <div class="input-group">
                            <label class="input-group-btn">
                                <span class="btn btn-default">
                                    Cargar logo <input type="file" style="display: none" accept="image/*" @change="onChange" />
                                </span>
                            </label>
                            <label class="text-error" v-if="property.image_error">Es requerido cargar una imagen</label>
                        </div>

                    </div>
                    <!--<div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.destiny')"
                                  :label="$t('form.destiny')"
                                  :placeholder="$t('form.destiny')"
                                  v-validate="modelValidations.destiny"
                                  :error="getError($t('form.destiny'))"
                                  v-model="property.destiny">
                        </fg-input>
                    </div>-->
                </div>



                <!--<div class="col-md-12 d-none">
                    <GmapAutocomplete @place_changed="setPlace">
                    </GmapAutocomplete>
                    <button @click="usePlace">Add</button>

                    <GmapMap style="width: 600px; height: 300px;" :zoom="1" :center="{lat: 0, lng: 0}">
                        <GmapMarker v-for="(marker, index) in markers"
                                    :key="index"
                                    :position="marker.position"
                        />
                        <GmapMarker
                                v-if="this.place"
                                label="★"
                                :position="{
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        }"
                        />
                    </GmapMap>
                    {{latLng.lat}},
                    {{latLng.lng}}
                </div>-->


                <div class="row">
                    <div class="col-md-3">
                        <fg-input type="text"
                                  :name="$t('form.mark')"
                                  :label="$t('form.mark')"
                                  :placeholder="$t('form.mark')"
                                  v-model="property.brand">
                        </fg-input>
                        <!--v-validate="modelValidations.url"-->
                    </div>
                    <div class="col-md-3">
                        <fg-input type="text"
                                  :name="$t('form.site_url')"
                                  :label="$t('form.site_url')"
                                  :placeholder="$t('form.site_url')"
                                  v-validate="modelValidations.url"
                                  :error="getError($t('form.site_url'))"
                                  v-show="info_page.tab_lang==='es'"
                                  v-model="property.es.url">
                        </fg-input>
                        <!--v-validate="modelValidations.url"-->
                        <fg-input type="text"
                                  :name="$t('property.form.site_url_lang')"
                                  :label="$t('property.form.site_url_lang')"
                                  :placeholder="$t('property.form.site_url_lang')"

                                  :error="getError($t('property.form.site_url_lang'))"
                                  v-show="info_page.tab_lang==='en'"
                                  v-model="property.en.url">
                        </fg-input>


                    </div>
                    <div class="col-md-3">
                        <div class="form-group has-label">
                            <label >{{$t('form.category')}}</label>
                            <fg-input
                                    v-validate="modelValidations.category"  :name="$t('form.category')"  v-model="property.category"
                                    :error="getError($t('form.category'))">
                                <el-select class="select-default" size="large" :placeholder="$t('form.category')"  v-validate="modelValidations.category" :name="$t('form.category')"
                                           v-model="property.category"  >
                                    <el-option v-for="option in rated.rateds" class="select-default" :value="option.value"
                                               :label="option.label+' '+$t('form.stars')" :key="option.label">
                                    </el-option>
                                </el-select>
                            </fg-input>


                        </div>
                    </div>
                    <div class="col-md-3">
                        <fg-input type="text"
                                  :name="$t('form.phone')"
                                  :label="$t('form.phone')"
                                  :placeholder="$t('form.phone')"
                                  v-validate="modelValidations.phone"
                                  :error="getError($t('form.phone'))"
                                  v-model="property.phone">
                        </fg-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :name="$t('form.street')"
                                  :label="$t('form.street')"
                                  :placeholder="$t('form.street')"
                                  v-validate="modelValidations.street"
                                  :error="getError($t('form.street'))"
                                  v-model="property.street">
                        </fg-input>
                    </div>
                    <div class="col-md-3">
                        <fg-input type="text"
                                  :name="$t('form.ext_number')"
                                  :label="$t('form.ext_number')"
                                  :placeholder="$t('form.ext_number')"
                                  v-validate="modelValidations.ext_number"
                                  :error="getError($t('form.ext_number'))"
                                  v-model="property.ext_number">
                        </fg-input>
                    </div>
                    <div class="col-md-3">
                        <fg-input type="text"
                                  :name="$t('form.int_number')"
                                  :label="$t('form.int_number')"
                                  :placeholder="$t('form.int_number')"
                                  v-model="property.int_number">
                        </fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :name="$t('form.suburb')"
                                  :label="$t('form.suburb')"
                                  :placeholder="$t('form.suburb')"
                                  v-validate="modelValidations.suburb"
                                  :error="getError($t('form.suburb'))"
                                  v-model="property.suburb">
                        </fg-input>
                    </div>
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :name="$t('form.zipcode')"
                                  :label="$t('form.zipcode')"
                                  :placeholder="$t('form.zipcode')"
                                  v-validate="modelValidations.zc"
                                  :error="getError($t('form.zipcode'))"
                                  v-model="property.zc">
                        </fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.city')"
                                  :label="$t('form.city')"
                                  :placeholder="$t('form.city')"
                                  v-validate="modelValidations.city"
                                  :error="getError($t('form.city'))"
                                  v-model="property.city">
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.state')"
                                  :label="$t('form.state')"
                                  :placeholder="$t('form.state')"
                                  v-validate="modelValidations.state"
                                  :error="getError($t('form.state'))"
                                  v-model="property.state">
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.country')"
                                  :label="$t('form.country')"
                                  :placeholder="$t('form.country')"
                                  v-validate="modelValidations.country"
                                  :error="getError($t('form.country'))"
                                  v-model="property.country">
                        </fg-input>
                    </div>
                    
                </div>




               <!-- <div class="row ">
                    <div class="col-md-6">
                        <div class="form-group has-label">
                            <label >{{$t('form.pre_cellphone')}}</label>
                            <el-select multiple class="select-default" size="large" :placeholder="$t('form.pre_cellphone')"
                                       v-model="lada.selected">
                                <el-option v-for="option in lada.ladas" class="select-default" :value="option.value"
                                           :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </div>-->


            </div>

            <div class="box-contain w-border">
                <div class="box-item">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check  mt-2">
                                        <label for="reservs" class="form-check-label mr-4">
                                            <input class="form-check-input" type="checkbox"  id="reservs"  v-model="property.included_taxes">
                                            <span class="form-check-sign" ></span>
                                            <label class="title-config mb-2 line-25">Tarifas cargadas con impuestos</label>
                                        </label>
                                    </div>

                                </div>
                                <div class="box-inside">
                                    
                                    <div class="col-12 mar-top">
                                        <fg-input type="text"
                                                  :name="$t('form.iva')"
                                                  :label="$t('form.iva')"
                                                  :placeholder="$t('form.iva')"
                                                  v-validate="modelValidations.iva"
                                                  :error="getError($t('form.iva'))"
                                                  v-model="property.iva">
                                        </fg-input>
                                    </div>
                                    <div class="col-12 mar-top">
                                        <fg-input type="text"
                                                  :name="$t('form.ish')"
                                                  :label="$t('form.ish')"
                                                  :placeholder="$t('form.ish')"
                                                  v-validate="modelValidations.ish"
                                                  :error="getError($t('form.ish'))"
                                                  v-model="property.ish">
                                        </fg-input>
                                    </div>
                                    <div class="col-12 mar-top">

                                        <fg-input
                                                  :label="$t('contract.form.currency')"
                                                  :name="$t('contract.form.currency')"  v-model="property.currency"  :error="getError($t('contract.form.currency'))">
                                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.currency')" :name="$t('contract.form.currency')"
                                                       v-model="property.currency" 
                                                  v-validate="modelValidations.currency" >
                                                <el-option v-for="option in info_page.currencys" class="select-default"  :value="option._id"
                                                           :label="option.name" :key="option._id">
                                                </el-option>
                                            </el-select>
                                        </fg-input>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="box-contain w-border">

                <div class="box-item">
                    <div class="row">
                        <div class="col-12">
                            <label class="title-config mb-4">{{ this.$t('property.config.payments')}}</label>
                            <div class="row"  v-for="item in info_page.payments">
                                <div class="col-sm-5">
                                    <label class="ul-item checked">{{item.name}}</label>
                                </div>
                                <!--<div class="col-sm-7">
                                    <span class="fa fa-check item-checked" :class="item.status?'checked':''" @click="item.status = !item.status"></span>
                                </div>
                                <div class="col-12">

                                    <label class="text-alert" v-show="item.text && item.status"><i class="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{{item.text}}</label>
                                </div>-->
                                <div class="col-12">
                                    <div class="item-payment" v-for="(paramet, idx) in item.params">
                                        <div class="row pl-5">
                                            <div class="col-2 pr-0">
                                                <label class="ul-item pl-0" v-if="idx!=='stripe_comision' || (currentUser.is_superadmin && idx==='stripe_comision')">
                                                    {{translateKeys(idx)}}
                                                </label>
                                            </div>
                                            <div class="col-10 ">

                                                <fg-input type="text"  v-if="idx!=='stripe_comision' || (currentUser.is_superadmin && idx==='stripe_comision')"
                                                          v-model="item.params[idx]">
                                                </fg-input>


                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="box-contain w-border">
                <div class="box-item">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <label class="title-config">Seguimiento E-Commerce</label>
                                </div>
                                <div class="col-12 mar-top">
                                    <span class="mr-2">ID  de contenedor Google Tag Manager</span>

                                    <fg-input type="text"
                                              v-model="property.gtm_id"
                                              class="inp-size" :class="error.gtm?'inp-error':''" placeholder="GTM-XXXXXXX"

                                    >
                                    </fg-input>

                                    <span class="lbl-error complete-line" v-show="error.gtm">Este campo es requerido</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="text-center mt-3 mb-3">
                    <button type="submit" class="btn btn-success btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                        {{info_page.button}}
                    </button>

                    <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Properties'})">
                        {{$t('form.cancel')}}
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import { vueTopprogress } from 'vue-top-progress';
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import Settings from "src/js/helpers/Settings";
    import Helpers from "src/js/helpers/Helpers";
    import EventBus from "src/js/helpers/EventBus";
    const propertyService = new PropertyService();
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    import axios from 'axios';
    import Vue from 'vue'
    import * as VueGoogleMaps from 'vue2-google-maps'
    let settings = new Settings();
    let helpers = new Helpers();

    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBJNF6PMBUZHqRgg9CQeFG7sLCKMLE6b8g',
            libraries: 'places',
        }
    });

    export default {
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Option.name]: Option,
            [Select.name]: Select,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser", "selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('property.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],

                info_page:{
                    original_info:{},
                    section_name: 'property',
                    action: 'add',
                    header: this.$t('property.new.header'),
                    button:  this.$t('form.save'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    payments:[
                        /*{
                            _id: 1,
                            name: 'Verificación tarjeta de credito',
                            options: [
                                {
                                    name: 'VISA',
                                    value: 'visa',
                                    status: true
                                },
                                {
                                    name: 'Mastercad',
                                    value: 'mastercard',
                                    status: true
                                },
                                {
                                    name: 'American Express',
                                    value: 'american-express',
                                    status: true
                                }
                            ],
                            status: true
                        },*/
                        {
                            _id: 2,
                            name: this.$t('property.config.type_pasarela'),
                            options: [],
                            text: 'Por favor ingresar el ID y las llaves de integración de OPENPAY',
                            params: {
                                tdc_key: '',
                                tdc_public: '',
                                tdc_secret: '',
                            },
                            status: true
                        },

                        {
                            _id: 3,
                            name: this.$t('property.config.type_paypal'),
                            options: [],
                            text: 'Por favor ingresar el ID y las llaves de integración de PAYPAL',
                            params: {
                                paypal_key: '',
                                paypal_secret: '',

                            },
                            status: false
                        },


                        {
                            _id: 4,
                            name: this.$t('property.config.type_firstdata'),
                            options: [],
                            text: 'Por favor ingresar el ID y las llaves de integración de FIRSTDATA',
                            params: {
                                firstdata_key: '',
                                firstdata_secret: '',
                            },
                            status: true
                        },




                        {
                            _id: 4,
                            name: this.$t('property.config.type_stripe'),
                            options: [],
                            text: 'Por favor ingresa la llave de integración de STRIPE',
                            params: {
                                stripe_secret: '',
                                stripe_public: '',
                                stripe_cuenta_id: '',
                                stripe_comision: '',
                            },
                            status: true
                        },



                        /*{
                            _id: 4,
                            name: this.$t('property.config.type_phv'),
                            options: [],
                            status: false
                        },*/


                    ],
                    currencys:[
                        {
                            _id: 'MXN',
                            name: 'MXN',

                        },
                        {
                            _id: 'USD',
                            name: 'USD',

                        }
                    ],
                },
                property: {
                    _id: '',
                    category: '',
                    phone: '',
                    street: '',
                    ext_number: '',
                    int_number: '',
                    suburb: '',
                    zc: '',
                    city: '',
                    state: '',
                    country: '',
                    brand: '',
                    logo: '',
                    currency: '',
                    image: null,
                    image_change: false,
                    image_error: false,
                    image_name: null,
                    es:{
                        name:'',
                        description:'',
                        url: ''
                    },
                    en:{
                        name:'',
                        description:'',
                        url: ''
                    },
                    payments:[
                        {
                            name: 'VISA',
                            value: 'visa'
                        },
                    ],
                    gtm: '',
                    included_taxes:false,
                    ish: '',
                    iva: '',

                },

                modelValidations: {
                    name: {
                        required: true
                    },
                    name_lang: {
                        required: true
                    },
                    description: {
                        required: true
                    },
                    url: {
                        required: true
                    },
                    category: {
                        required: true
                    },
                    phone: {
                        required: true,
                        min: 10
                    },
                    street: {
                        required: true
                    },
                    ext_number: {
                        required: true
                    },
                    zc: {
                        required: true,
                        min: 5
                    },
                    suburb: {
                        required: true
                    },
                    city: {
                        required: true
                    },
                    state: {
                        required: true,
                        min: 3
                    },
                    country: {
                        required: true,
                    },
                    iva:{
                        required: true,
                        max_value: 99,
                        min_value: 0
                    },
                    ish: {
                        required: true,
                        max_value: 99,
                        min_value: 0
                    },
                    currency: {
                        required: true
                    }
                },

                rated: {
                    selected:1,
                    rateds:[
                        {
                            label: '5',
                            value: 5
                        },
                        {
                            label: '4.5',
                            value: 4.5
                        },
                        {
                            label: '4',
                            value: 4
                        },
                        {
                            label: '3.5',
                            value: 3.5
                        },
                        {
                            label: '3',
                            value: 3
                        },
                        {
                            label: '2.5',
                            value: 2.5
                        },
                        {
                            label: '2',
                            value: 2
                        },
                        {
                            label: '1.5',
                            value: 1.5
                        },
                        {
                            label: '1',
                            value: 1
                        },

                    ]
                },


                place: null,
                latLng: {
                    lat: 10.0,
                    lng: 10.0,
                },
                markers: [],
                error:{
                    gtm: false,
                    paypal_key: false,
                    paypal_secret: false,
                    tdc_key: false,
                    tdc_public: false,
                    tdc_secret: false,

                },
                section: 'PROPERTY'

            }
        },
        created(){
          this.initPage();
          this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            initPage(){
               // console.log('router', settings.getCurrentUser() );
                this.initLoading();
                this.configLogs(this.selected_property._id, this.info_page.section_name);

               //console.log('data', this.$store.properties);

                if(this.$route.name==='editProperty'){

                    let property_id = this.$route.params.id;
                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('property.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('property.edit.header');

                    this.updateData(property_id);
                }else{

                    setTimeout(() => {
                        this.closeLoading();
                    }, 500);
                }
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            updateData(property_id) {
                propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    this.info_page.original_info = {...data}



                    this.property._id = data._id;
                    this.property.category= data.category;
                    this.property.phone= data.phone;
                    this.property.street= data.street;
                    this.property.ext_number= data.ext_number;
                    this.property.int_number= data.int_number;
                    this.property.suburb= data.suburb;
                    this.property.zc=data.zc;
                    this.property.city= data.city;
                    this.property.state=data.state;
                    this.property.country=data.country;
                    this.property.logo=data.logo;
                    this.property.brand = data.brand;
                    this.property.gtm_id = data.gtm_id;
                    this.property.included_taxes = data.included_taxes;
                    this.property.iva = data.iva;
                    this.property.ish = data.ish;
                    this.property.currency = data.currency;


                    /*********** Seccion configuracion de colores *************/
                    this.info_page.payments[0].params.tdc_key = data.tdc_key;
                    this.info_page.payments[0].params.tdc_public = data.tdc_public;
                    this.info_page.payments[0].params.tdc_secret = data.tdc_secret;
                    this.info_page.payments[1].params.paypal_key = data.paypal_key;
                    this.info_page.payments[1].params.paypal_secret = data.paypal_secret;

                    this.info_page.payments[2].params.firstdata_key = (data.firstdata_key!==undefined)?data.firstdata_key:'';
                    this.info_page.payments[2].params.firstdata_secret = (data.firstdata_secret!==undefined)?data.firstdata_secret:'';
                    this.info_page.payments[3].params.stripe_secret = (data.stripe_secret!==undefined)?data.stripe_secret:'';
                    this.info_page.payments[3].params.stripe_public = (data.stripe_public!==undefined)?data.stripe_public:'';
                    this.info_page.payments[3].params.stripe_cuenta_id = (data.stripe_cuenta_id!==undefined)?data.stripe_cuenta_id:'';
                    this.info_page.payments[3].params.stripe_comision = (data.stripe_comision!==undefined)?data.stripe_comision:'';
                    //stripe_cuenta_id
                    // console.log('propiedades', this.property);

                    this.property.es.name = data.name;
                    this.property.es.description = data.description;
                    this.property.es.url = data.url;
                    this.closeLoading();
                });
            },
            sendForm(action){

                this.validate(action);

            },
            async saveItem(){

                this.initLoading('Guardando');




                let property_data ={
                    name: this.property.es.name,
                    description: this.property.es.description,
                    url: this.property.es.url,
                    category: this.property.category,
                    phone: this.property.phone,
                    street: this.property.street,
                    brand: this.property.brand,
                    ext_number: this.property.ext_number,
                    int_number: this.property.int_number,
                    suburb: this.property.suburb,
                    zc: this.property.zc,
                    city: this.property.city,
                    state: this.property.state,
                    country: this.property.country,
                    tdc_key: this.property.tdc_key,
                    tdc_public: this.property.tdc_public,
                    tdc_secret: this.property.tdc_secret,
                    paypal_key: this.property.paypal_key,
                    paypal_secret: this.property.paypal_secret,
                    only_adults: true,
                    gtm_id: this.property.gtm_id,
                    included_taxes: this.property.included_taxes,
                    iva: this.property.iva,
                    ish: this.property.ish,
                    currency: this.property.currency,
                    color_header: "#6B3E91",
                    color_main: "#6B3E91",
                    color_second: "#142152"
                }
                this.info_page.payments.forEach(payment=>{
                    for (let prop in payment.params) {
                       //console.log('map test', payment.params[prop]);
                        property_data[prop] = payment.params[prop];

                    }
                });


                let property = {};

                     await propertyService.createProperty(property_data).then(response_property => {

                        property = response_property.data.data.property
                    }).catch(error => {
                        this.closeLoading();
                        console.log('error', error.response.data.err);
                       let msg = error.response.data.err;
                        swal({
                            title: 'Operación fallida',
                            text: msg,
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-success btn-fill',
                            type: 'warning'
                        })
                        return;
                    });
                    //console.log('response_property', response_property)
                    
                
                //console.log('response_property', property)
                let item = {
                    roles: [2,2,2,2,2,2],
                    property: property._id
                }


                //this.log_page.property_id = property._id;
                await this.createLog(property._id, 0)


                if(this.property.image_change){
                    let image = await this.processImage(this.property.logo, this.property.image_name)
                    let ext = image.type.replace('image/', '');
                    let formData = new FormData();
                    formData.append('file', image, image.name);
                    formData.append('property', this.property.es.name);
                    await propertyService.uploadLogo(property._id, formData, 'PUT')
                }

                let user_id = this.currentUser._id;
                await propertyService.createUserRoles(user_id, item);

                let user_response = await propertyService.getUserId(user_id);
                let user = user_response.data.data.user;
                let permissions = (user.permissions)?user.permissions:[]
                let response_properties = await propertyService.getProperties();
                let properties_all = response_properties.data.data.properties;

                let properties = [];

                properties_all.forEach(async tnmt => {
                    let idx = permissions.findIndex(item => item.property === tnmt._id);
                    //console.log('idx', idx)
                    if(idx>=0){
                        properties.push({
                            _id:tnmt._id,
                            logo:tnmt.logo,
                            name:tnmt.name,
                        })
                    }
                });

                let property_actually = properties_all.find(x => x._id === property._id)
                //console.log('property_actually', property_actually)
                let info_property = {
                    _id:property_actually._id,
                    logo:property_actually.logo,
                    name:property_actually.name,
                }
                localStorage.setItem('ryp', JSON.stringify(permissions));
                localStorage.setItem('properties', JSON.stringify(properties));
                localStorage.setItem('property', JSON.stringify(info_property));
                EventBus.$emit('RELOAD_PROPERTIES');
                EventBus.$emit('UPDATE_MENU');
                EventBus.$emit('CLICK_PROPERTY', property_actually._id);


                await this.$store.dispatch('ADD_PROPERTY',property_actually);

                this.closeLoading();


                 this.showSwal(this.$t('property.new.msg'));

            },
            async updateItem(){



                this.initLoading('Guardando');


                if(this.property.image_change){
                    let image = this.processImage(this.property.logo, this.property.image_name)
                    let ext = image.type.replace('image/', '');
                   //console.log(image, 'img')

                    let formData = new FormData();
                    console.log(this.property.es.name);
                    formData.append('file', image, image.name);
                    formData.append('property', this.property.es.name);



                    //this.console.log()
                   //console.log(formData, 'data')
                    //this.property.image = formData;

                    let resp = await propertyService.uploadLogo(this.property._id, formData, 'PUT')
                }




                let property_data ={
                    _id: this.property._id,
                    name: this.property.es.name,
                    description: this.property.es.description,
                    url: this.property.es.url,
                    category: this.property.category,
                    phone: this.property.phone,
                    street: this.property.street,
                    brand: this.property.brand,
                    ext_number: this.property.ext_number,
                    int_number: this.property.int_number,
                    suburb: this.property.suburb,
                    zc: this.property.zc,
                    city: this.property.city,
                    state: this.property.state,
                    country: this.property.country,
                    tdc_key: this.property.tdc_key,
                    tdc_public: this.property.tdc_public,
                    tdc_secret: this.property.tdc_secret,
                    paypal_key: this.property.paypal_key,
                    paypal_secret: this.property.paypal_secret,
                    gtm_id: this.property.gtm_id,
                    included_taxes: this.property.included_taxes,
                    iva: this.property.iva,
                    ish: this.property.ish,
                    currency: this.property.currency
                }
                this.info_page.payments.forEach(payment=>{
                    for (let prop in payment.params) {
                       //console.log('map test', payment.params[prop]);
                        property_data[prop] = payment.params[prop];

                    }
                });


                let property = {};

                     await propertyService.updateProperty(property_data).then(response_property => {

                        property = response_property.data.data.property
                    }).catch(error => {
                        this.closeLoading();
                        console.log('error', error.response.data.err);
                       let msg = error.response.data.err;
                        swal({
                            title: 'Operación fallida',
                            text: msg,
                            buttonsStyling: false,
                            confirmButtonClass: 'btn btn-success btn-fill',
                            type: 'warning'
                        })
                        return;
                    });

                /*let response_property = await propertyService.updateProperty(property_data)
                let property = response_property.data.data.property*/

                //this.log_page.property_id = property._id;
                let changes = logFunctions.cheackProp(this.info_page.original_info, property, this.info_page.section_name)
                if(changes.length){
                    await this.createLog(property._id, 1, changes);
                }
                let user_id = this.currentUser._id;

                let user_response = await propertyService.getUserId(user_id);
                let user = user_response.data.data.user;
                let permissions = (user.permissions)?user.permissions:[]
                let response_properties = await propertyService.getProperties();
                let properties_all = response_properties.data.data.properties;

                let properties = [];

                properties_all.forEach(async tnmt => {
                    let idx = permissions.findIndex(item => item.property === tnmt._id);
                    //console.log('idx', idx)
                    if(idx>=0){
                        properties.push({
                            _id:tnmt._id,
                            logo:tnmt.logo,
                            name:tnmt.name,
                        })
                    }
                });

                let property_actually =properties_all.find(x => x._id === property._id)
                let info_property = {
                    _id:property_actually._id,
                    logo:property_actually.logo,
                    name:property_actually.name,
                }
                localStorage.setItem('properties', JSON.stringify(properties));
                localStorage.setItem('property', JSON.stringify(info_property));
                EventBus.$emit('RELOAD_PROPERTIES');
                EventBus.$emit('CLICK_PROPERTY', property_actually._id);
                EventBus.$emit('UPDATE_MENU');

                await this.$store.dispatch('UPDATE_PROPERTY', property);
                    /*.then((response) => {
                        console.log('property', response.data);

                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => {
                    });*/
                this.closeLoading();
                this.showSwal(this.$t('property.edit.msg'));
            },
            showSwal (msg) {

                    swal({
                        title: this.$t('property.new.modal'),
                        text: msg,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        type: 'success'
                    }).then(()=> {
                        this.$router.push({
                            name: 'Properties'
                        });
                    });
            },
            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {

                if(this.property.logo===''){
                    this.property.image_error =  true;
                }
                let logo = this.property.logo

                this.$validator.validateAll().then(isValid => {
                    if(isValid && logo !==''){


                        if(action==='add'){
                            this.saveItem();
                        }else{
                            this.updateItem();
                        }
                    }

                   //console.log('validado?', isValid)


                    //this.$emit('on-submit', this.registerForm, isValid)
                })
            },


            setDescription(description) {
                this.description = description;
            },


            setPlace(place) {
               //console.log('place', place)
                this.place = place
            },
            usePlace(place) {
                //console.log(place)
                if (this.place) {
                    this.markers.push({
                        position: {
                            lat: this.place.geometry.location.lat(),
                            lng: this.place.geometry.location.lng(),
                        }
                    })
                    this.place = null;
                }
            },

            onChange(e) {
               //console.log('event', e.target.files)
                if(e.target.files.length){
                    this.property.image_change = true;
                    const image = e.target.files[0];
                    const reader = new FileReader();
                    this.property.image = image
                    reader.readAsDataURL(image);
                    reader.onload = e =>{
                        this.property.logo = e.target.result;
                        this.property.image_name =  image.name
                       //console.log(this.property.logo);
                    };
                }
            },
            processImage(data, filename) {

                let arr = data.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);

                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1);
                    n -= 1;
                }

                return new File([u8arr], filename, { type: mime })
            },
            translateKeys(value){
                if(value==='tdc_key')
                    value = 'ID:';
                if(value==='tdc_public'  || value==='stripe_public')
                    value = 'Llave Pública:';
                if(value==='tdc_secret')
                    value = 'Llave Privada:';
                if(value==='paypal_key')
                    value = 'Cliente ID:';
                if(value==='paypal_secret' || value==='firstdata_secret' || value==='stripe_secret')
                    value = 'Llave Secreta:';
                if(value==='firstdata_key')
                    value = 'Llave API:';
                if(value==='stripe_cuenta_id')
                    value = 'Cuenta ID:';
                if(value==='stripe_comision')
                    value = 'Comisión %:';



                return value;
            },
            validateCustom(){
                datas  = {
                    pay_tdc: this.info_page.payments[0].status,
                    pay_paypal: this.info_page.payments[1].status,
                    pay_hotel: this.info_page.payments[2].status,
                };



                this.info_page.payments.forEach(payment=>{
                    if(payment.status){
                        for (let prop in payment.params) {
                           //console.log('map test', payment.params[prop]);



                            this.error[prop] = false;
                            if(payment.params[prop].trim().length === 0){
                                this.error[prop] = true;
                                errors++;
                            }else{
                                datas[prop] = payment.params[prop];
                            }

                        }
                    }
                });

            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }
        },
    }

</script>
<style lang="scss">
    .pad-custom {
        padding: 32px 15px 21px !important;
    }
    .box-logo{
        padding: 0 15px;
        &.with-img{
            border: none;
        }
        width: 100%;
        height: 192px;
        border: 2px solid #e3e3e3;
        text-align: center;
        display: flex;
        i{
            font-size: 60px;
            color: #b3b3b3;
            width: auto;
            margin: auto;
        }
        img{
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
            margin: auto;
        }
    }
    .input-group{
        margin-top: 20px;
        .input-group-btn{
            text-align: center;
            width: 100%;
        }

    }
    .custom-error{
        .text-error{
            text-transform: none;
            font-size: 12px;
            color: #FB404B;

        }
    }
    .w-border{
        border:2px solid #bebebe;
        padding: 10px 25px;
        margin-bottom: 15px;
    }


    .box-contain{
        .w-200{
            width: 240px;
            display: inline-block;
        }
        .title-config{
            text-transform: none;
            display: inline-block;
            font-size: 20px;
            color: #000000;
            line-height: 37px;
            margin-bottom: 0;
            &.line-25{
                line-height: 25px;
            }
        }
        .inp-size{
            padding: 0 7px;
            width: 300px;
            display: inline-block;
        }
        .mar-top{
            padding-top: 4px;
        }
        .ul-item{
            text-transform: none;
            font-size: 15px;
            line-height: 40px;
            &.checked{
                color: #000000;
            }

        }
        .item-checked{
            color: #a3a3a3;
            &.checked{
                color: #000000;
            }
        }
        .text-alert{
            text-transform: none;
            margin-left: 30px;
            margin-bottom: 10px;
            color: goldenrod;
            font-size: 15px;
        }
        .box-inside{
            position: relative;
            .box-glass{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.6;
                background: white;
                z-index: 5;
            }
        }

        .item-payment{
            width: 100%;
            display: inline-block;
            float: left;
            .lbl-error{
                margin-bottom: 5px;
                display: inline-block;
            }
            input{
                font-size: 13px;
                width: 100%;
                margin: 0 0 7px;
                padding: 0 7px;
                &.inp-error{
                    margin-bottom: 0;
                }
            }

        }
    }
</style>
